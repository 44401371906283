/* #### Generated By: http://font.download #### */

@font-face {
    font-family: 'Futura PT Book';
    font-style: normal;
    font-weight: normal;
    /* src: local('Futura PT Book'), url('FuturaCyrillicBook.woff') format('woff'); */
    src: local('Futura PT Book '), url('../public/Fonts/FuturaCyrillicBook.woff') format('woff')
}


@font-face {
    font-family: 'Futura PT Light';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Light'), url('../public/Fonts/FuturaCyrillicLight.woff') format('woff');
}


@font-face {
    font-family: 'Futura PT Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Medium'), url('../public/Fonts/FuturaCyrillicMedium.woff') format('woff');
}


@font-face {
    font-family: 'Futura PT Demi';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Demi'), url('../public/Fonts/FuturaCyrillicDemi.woff') format('woff');
}


@font-face {
    font-family: 'Futura PT Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Heavy'), url('../public/Fonts/FuturaCyrillicHeavy.woff') format('woff');
}


@font-face {
    font-family: 'Futura PT Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Bold'), url('../public/Fonts/FuturaCyrillicBold.woff') format('woff');
}


@font-face {
    font-family: 'Futura PT Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Futura PT Extra Bold'), url('../public/Fonts/FuturaCyrillicExtraBold.woff') format('woff');
}