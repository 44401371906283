.container {
    padding: 0px 20px 20px 20px;
    font-family: 'Futura PT', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    background-color: #ffffff; /* Light background for better contrast */
    min-height: 100vh; /* Ensure full height of the viewport */
  }
   
  .header {
    text-align: center;
    margin-bottom: 30px;
    font-weight: 600; /* Make the header slightly bolder */
    color: #333; /* Darker text color */
  }
   
  .loading, .error {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }
   
  .error {
    color: #ff4c4c;
  }
   
  .stock-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* padding-top: 10px; */
    /* padding-bottom: 50px; */
  }
   
  .stock-box {
    display: flex;
    width: 100%;
    max-width: 3000px; /* Limit the width for better readability */
    justify-content: center;
    /* gap: 20px; Increase the gap for better separation */
  }
   
  .stock-list {
    width: 50%; /* Use percentage to allow for responsive design */
    /* padding: 10px; */
    background-color: #fff; /* White background for the lists */
    border-radius: 8px; /* Rounded corners */
  }
   
  .stock-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px; /* Slightly larger gap between items */
  }
   
  .bar {
    height: 18px; /* Increase bar height for better visibility */
    max-width: 100%; /* Ensure bars stay within their container */
    border-radius: 4px; /* Round the bar edges */
  }
   
  .positive-stock-bar {
    background-color: #4caf50; /* Slightly lighter green */
    /* margin-right: 10px; Add space between bar and text */
    margin: 0px 5px;
}

.negative-stock-bar {
    background-color: #f44336; /* Slightly lighter red */
    margin: 0px 5px;
    /* margin-left: 10px; Add space between bar and text */
  }
   
  .medium-text {
    font-size: 14px;
    font-weight: 500;
    color: #555; /* Medium gray text for better contrast */
  }
   
  .stock-price {
    /* margin-left: 10px; */
    font-weight: 600; /* Bold the price for emphasis */
  }
   
  .stock-item .positive-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: left;
  }
   
  .stock-item .negative-text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right; /* Ensure text aligns properly with the bar */
  }

  .stock-item-n{
    justify-content: start;
  }
  .stock-item-p{
    justify-content: end;
  }