.about-page {

    .founder {
        background-color: #134873;
        padding: 3% 0%;

        .founder-img {
            height: 400px;
            background: #fff;
            width: 80%;
            margin: auto;
            display: flex;
            /* align-items: center; */
            gap: 10%;

            img {
                height: -webkit-fill-available;
                padding: 18px;
            }

            .about-founder {
                padding-top: 25px;
                width: 100%;

                h1 {
                    /* color: #D0111C;
                    margin-top: 10px;
                    font-size: 26px;
                    font-weight: bold; */
                    color: #7d663b;
                    margin-top: 10px;
                    font-size: 26px;
                    font-weight: bold;
                    text-shadow: 2px 2px 4px rgb(15 53 83);
                }

                h2 {
                    color: #444444;
                    /* display: flex; */
                    /* justify-content: center; */
                    margin-top: 10px;
                    font-size: 22px;
                    font-weight: bold;
                }

                p {
                    margin-top: 10px;
                    font-size: 17px;
                }
            }

        }
    }

    .about-video {
        height: 421px;
        padding-top: 10px;

        .video-background {
            position: relative;
            width: 100%;
            height: 400px;
            overflow: hidden;
        }

        #background-video {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            z-index: -1;
        }

        .content {
            position: absolute;
            z-index: 1;
            top: 1px;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            height: 100%;
            background: #d9d9d97a;


        }

    }

    /* .portfolio {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 50px;
        background-color: #1c456c;

        .services {
            display: flex;
            flex-direction: column;
        }

        .service-btn {
            background-color: white;
            color: #1c456c;
            border: none;
            padding: 15px;
            margin: 5px 0;
            cursor: pointer;
            text-align: left;
            font-size: 16px;
            border-radius: 5px;
        }

        .service-btn.active {
            background-color: #3c6d9b;
            color: white;
        }

        .service-btn:hover {
            background-color: #003366;
            color: white;
        }

        .video {
            background-color: #eeeeee;
            border-radius: 5px;
            width: 500px;
            height: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .video-placeholder {
            padding: 0px 30px;
            background-color: #cccccc;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            height: -webkit-fill-available;
        
        }

        .video-placeholder img {
            width: 50px;
            height: 50px;
        }

        .video p {
            color: #333333;
            font-size: 14px;
            text-align: center;
            margin-top: 10px;
        }

    } */

    .about-tradabulls {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

        .content {
            display: flex;
            text-align: left;
            margin-bottom: 20px;
            width: 80%;
        }

        .content h1 {
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 20px;
            font-family: "Futura PT Book";

            span {
                font-family: "Futura PT Demi";
            }
        }

        .content p {
            max-width: 600px;
            font-size: 1rem;
            line-height: 1.6;
            color: #333;
        }

        .image-container {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
        }

        .image-container img {
            max-width: 100%;
            height: auto;
        }

        .line-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        }

        .line {
            width: 90%;
            height: 2px;
            background-color: black;
            position: relative;
        }

        .dot {
            position: absolute;
            left: 0;
            bottom: -6px;
            width: 12px;
            height: 12px;
            background-color: black;
            border-radius: 50%;
        }
    }
}

/* Wrapper for the QR code and close button */
.qr-wrapper {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
    display: block;
}

/* Smooth fade-in animation */
.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.8);
        /* Start smaller */
    }

    100% {
        opacity: 1;
        transform: scale(1);
        /* End at normal size */
    }
}

.qr-code {
    width: 100px;
    /* Initial size */
    animation: shake 10s infinite;
    /* 10s cycle: 5s shake, 5s stop */
    transition: transform 0.5s ease-in-out, bottom 0.5s, left 0.5s;
    transform-origin: center;
    position: relative;
    user-drag: none;
    /* Disable drag on WebKit browsers */
    -webkit-user-drag: none;
    /* Disable drag on Safari/Chrome */
}

/* Modified shaking animation */
@keyframes shake {

    0%,
    100% {
        transform: translate(0, 0);
        /* No movement */
    }

    /* Shaking for the first 5 seconds */
    10%,
    90% {
        transform: translate(-2px, 2px);
    }

    20%,
    80% {
        transform: translate(2px, -2px);
    }

    30%,
    50%,
    70% {
        transform: translate(-2px, 2px);
    }

    40%,
    60% {
        transform: translate(2px, -2px);
    }

    /* 5 seconds of stillness from 50% to 100% */
    50%,
    100% {
        transform: translate(0, 0);
        /* Stop shaking */
    }
}

/* Hover effect to enlarge, disable shaking, and reposition the image */
.qr-code:hover {
    animation: none;
    /* Turn off shaking */
    transform: scale(2);
    /* Scale up */
    transition: transform 0.5s ease-in-out, bottom 0.5s, left 0.5s;
    /* Smooth transition */
    bottom: 40px;
    /* Move the image upwards slightly */
    left: 40px;
    /* Move the image to the right slightly */
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    /* Add shadow */
}

/* Hide the close button immediately on hover */
.qr-wrapper:hover .close-btn {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    /* Immediate disappearance */
}

/* Updated close (cross) button styles */
.close-btn {
    position: absolute;
    top: -15px;
    right: -15px;
    background-color: #333;
    /* Neutral dark color */
    color: white;
    border: none;
    /* Remove border */
    border-radius: 50%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
    /* Stronger shadow for better depth */
    font-weight: bold;
    /* Bolder font for visibility */
    transition: transform 0.3s ease-in-out, background-color 0.3s ease;
}

/* Slider button with bounce animation */
.slider-btn {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 999;
    padding: 10px;
    background-color: #f3f6f9;
    color: #264c73;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    animation: bounce 2s infinite;
    font-weight: bold;
}

/* Bouncing animation for the slider button */
@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

/* Hover effect to slightly enlarge the slider button */
.slider-btn:hover {
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
}