.sp-container {
    padding: 0px !important;

    h3 {
        color: #D0111C;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e1e8f0;
    /* Light blue background */
    padding: 20px;
    margin-bottom: 40px;
}

.header-text {
    flex: 1;
}

.header-image img {
    max-width: 100%;
    height: auto;
}

.section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.section .text-section,
.section .image-section {
    flex: 1;
    padding: 20px;
}

.section img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.trading-journal {
    margin-top: 40px;
}

.journal-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    /* margin-top: 20px; */
    padding: 30px 100px;
}

.j-card {
    flex: 1 1 calc(33.333% - 20px);
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.j-card:hover{
    background-color: #fff;
    cursor: default;
}

.j-card h4 {
    margin-bottom: 10px;
    color: #fff;
    background-color: #184b70;
    border-radius: 4px;
    width: fit-content;
    min-width: 30px;
    padding: 0px 5px;
    /* Red color for headings */
}

.card p {
    color: #333;
}



.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e1e8f0;
    /* Light blue background */
    padding: 20px;
    background: linear-gradient(to right, #1e5799 0%, #7db9e8 100%);
    /* Blue gradient background */
}

.header-text {
    flex: 1;
    padding: 20px;
    color: #ffffff;
    /* White text color */
}

.header-text h2 {
    font-size: 1.8em;
    line-height: 1.4;
}

.highlight {
    font-weight: bold;
}

.header-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}