.slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px auto;
  /* Center the component horizontally */
  padding: 10px 0px;
  background-color: #DCE9F3;
}

.label,
.point {
  font-family: "Futura PT Book";
  font-weight: 900 !important;
  font-size: 12px;
  margin: auto;
}

.points {
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
}

.labels {
  display: flex;
}

.slider-bar {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: #8d8c8c;
  border-radius: 5px;
}

.slider-progress {
  position: absolute;
  height: 10px;
  /* background-color: green; */
  /* border-radius: 5px; */
  top: -4px;
}

.perc {
  /* margin-left: -2px; */
  font-size: 10px !important;
  padding: 0px;
  height: 15px;
  font-weight: bold;
}