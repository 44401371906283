.auth-wrapper {
    min-height: 100vh;
    position: relative;
}

.input-field {
    border: 1px solid;
    border-radius: 4px;
}

/* .login-container{
    display: flex;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(43deg, #4985be, transparent);
} */
.login-card {
    display: flex;
    /* width: 30%; */
    /* height: 100%; */
    align-items: center;
    justify-content: center;
}

/* new css for popup */
.dialog-box .MuiDialog-paperWidthSm {
    max-width: 65% !important;
    border-radius: 15px;
    padding-bottom: 10px;

}

.dialog-content {
    display: flex;
    padding: 8px 24px 24px !important;
}

.image-container {
    width: 70%;
}

.login-image {
    display: flex;
    justify-content: center;
}

.login-image img {
    width: 300px;
}

.company-logo {
    display: flex;
    justify-content: center;
}

.info-headding {
    display: flex;
    justify-content: center;
    font-size: 18px !important;
    font-weight: 700 !important;
}

.info-content {
    display: flex;
    justify-content: center;
    width: 70%;
    margin: auto !important;
    color: #666666;
    font-size: 13px !important;
}

.welcom-text {
    display: flex;
    justify-content: center;
    color: #154A72 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}

.login-paper {
    width: '20rem' !important;
    box-shadow: 'none' !important;
    padding-right: '15px';
}

.login-text {
    display: flex;
    justify-content: center;
    color: #666666 !important;
    font-size: 13px !important;
}

/* captcha css  */
.green-input {
    border-radius: 5px;
    border: 1px solid green;
}

input:focus-visible {
    outline: none;
}

.red-input {
    border-radius: 5px;
    border: 1px solid red;
}

.login-button button {
    background-color: #154A72 !important;
    color: #fff;
    width: 100%;
}

.login-button :hover {
    opacity: 0.8;
    background-color: #154A72 !important;
    color: #fff;
}

.MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.00) !important;
}

/* svg {
    width: 18px;
    margin-right: 13px;
} */

@media screen and (max-width: 600px) {
    .login-card {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 12px;
    }
}

@media screen and (max-width: 600px) {
    .close-icon{

        svg{
            color: #666666 !important;
        } 
    }
    .dialog-box .MuiDialog-paperWidthSm {
        max-width: 100% !important;
        border-radius: 15px;
        padding-bottom: 10px;

    }

    .dialog-content {
        padding:  0px !important;
    }
    .login-paper {
        width: fit-content !important;
        box-shadow: none !important;
        /* padding-right: '15px'; */
    }
}