.blinking-button {
    animation: blink-animation 1s infinite;
    background-color: #154A72; /* Default Material-UI primary color */
    color: white;
    border-radius: 50px;
    padding: 2px ;
    width: 25px !important;
    cursor: pointer;
}

@keyframes blink-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
