.video-bar {
    padding: 0.5% 0%;
    width: 100%;
    background: url('../../assets/images/Rectangle30.png');
    background-size: 80rem 27rem;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-around;

    .text-container {
        padding: 20px;

        .video-text {
            font-size: 40px;
            color: #000000;

            span {
                display: block;
                color: #CE1122;
                font-weight: bolder;
            }
        }

        .empover-text {
            font-size: 30px;
            color: #124972;
            font-weight: bolder;
            margin-top: 1rem;

            span {
                display: block;
                color: #000000;
                font-weight: 300;
                font-size: 24px;
            }
        }
    }

    .video-play {
        display: flex;
        justify-content: center;
        margin: 10px;

        video {
            height: 375px;
            border-radius: 40px;
        }
    }
}