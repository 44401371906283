#header {
  height: 65px !important;
  transition: all 0.5s;
  z-index: 997;
  background: #fff;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
}

#header #logo h1 {
  font-size: 42px;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

#header #logo h1 a {
  color: #0c2e8a;
}

#header #logo h1 a span {
  color: #50d8af;
}

#header #logo img {
  padding: 0;
  margin: 0;
}

.hover-icons {
  display: block;
}


a {
  margin: 0px !important;
}

.nav-link-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.nav-link-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 0 10px 30px; */
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #444;
  white-space: nowrap;
  transition: 0.3s;
}

.sideNav {
  display: grid !important;
}

.app-bar-buttons {
  .active {
    span {
      color: #d0111c;
      font-weight: bold;
      border-radius: 4px;
      transition: background-color 0.3s;
      /* text-decoration: underline; */
      padding-bottom: 0px;
    }
  }
}