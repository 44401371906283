.mf-container {
    .search-text {
        display: flex;
        justify-content: end;
        width: 95%;

        .search-field {
            width: 16%;
            margin: 10px 0px 5px 0px;

            input {
                padding: 0px !important;
                font-size: 18px !important;
            }
            label{
                top: -10px !important;
                font-family: "Futura PT Book" !important;
            }
        }
    }

    .table-container {
        height: 83vh;
        width: 100dvw;
        margin: auto;
        font-family: 'Futura Book PT';
    }
    .centered-cell {
        text-align: center !important;
      }
    .MuiDataGrid-root {
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 8px;
    }

    .MuiDataGrid-columnHeaders {
        background-color: #f0f0f0;
        font-weight: bold;
        font-size: 1rem;
        color: #333;
        border-bottom: 2px solid #ccc;
    }

    .MuiDataGrid-cell {
        font-size: 0.95rem;
        color: #444;
        padding: 12px;
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }

    .MuiDataGrid-cell:last-child {
        border-right: none;
    }

    .MuiDataGrid-row {
        &:nth-of-type(even) {
            background-color: #fafafa;
        }
    }

    .MuiDataGrid-row:last-of-type .MuiDataGrid-cell {
        border-bottom: none;
        /* Remove bottom border from last row */
    }

    .MuiDataGrid-virtualScroller::-webkit-scrollbar {
        width: 8px;
    }

    .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
    }

    .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
        background-color: #4b4848;
    }

    .css-1qb993p-MuiDataGrid-columnHeaderTitle {
        font-family: 'Futura PT Demi' !important;
    }

    .css-4k60b2-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
        font-family: 'Futura PT Book' !important;
    }

    /* .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row], .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]{
        background: #114d74 !important;
        color: #fff;

        svg {
            color: #fff;
        }
    } */
    .MuiDataGrid-container--top [role="row"]{
        background: #114d74 !important;
        color: #fff;
        font-weight: bold;

        svg {
            color: #fff;
        }
    }
    .MuiDataGrid-main{
        font-family: 'Futura PT Book' !important;
    }

}
