.footer-details {
    display: flex;
    justify-content: space-around;
    background: rgba(0, 48, 84, 1);
    padding: 5% 2% 3%;
}

.footer__link--header {
    color: rgba(38, 180, 75, 1);
    font-size: 18px;
    font-weight: 600;
}

.footer__link {
    display: flex;
    align-items: center;
    color: #fff;
    font-family: 14px;
    padding: 8px;
    /* max-width: 35vw; */
}

.touch-withus {
    display: flex;
    gap: 5px;
}

.touch-withus input {
    background: transparent;
    border: 1px solid;
    border-radius: 8px;
    padding: 5px 10px;
    color: #fff;
}

.touch-withus textarea {
    background: transparent;
    border: 1px solid;
    border-radius: 8px;
    padding: 5px 10px;
    color: #fff;
    width: 100%;
    margin-top: 10px;
}

.submit-button {
    background-color: #fff !important;
    color: #444444;
    padding: 10px 20px;
    font-size: 13px;
    border-radius: 8px;
}

.footer__bottom {
    background: rgba(0, 48, 84, 1);
    color: rgba(161, 196, 255, 1);
    padding: 2% 3%;
    border-top: 1px solid;
}

.join-now {
    color: #fff;

    svg {
        color: #fff;
    }
}

/* for tab  */
@media screen and (max-width: 950px) {
    .touch-withus {
        display: grid;
        gap: 10px;
    }
}

/* for mobile  */
@media screen and (max-width: 450px) {
    .footer-details{
        display: block;
        padding: 2% 5%;
    }

    .list-unstyled{
        max-width: 100% !important;
    }
    .touch-withus {
        margin-top: 15px;
    }
    .submit-button{
        width: 100%;
    }
}
.pdf {
    .rpv-core__page-layer--single {
        width: 100% !important;    
    }
    canvas {
        width: 100% !important;
    }
}
.rpv-core__canvas-layer {
    /* width: 100% !important;    */
    margin: auto !important; 
}