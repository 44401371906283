#top-bar {
background: #fff;
border-bottom: 1px solid #eee;
font-size: 14px;
padding: 0;
height: 40px;
}


  #hero {
    width: 100%;
    height: 60vh;
    position: relative;
   /* background: url("../../assets/images/hero-carousal/1.jpeg") no-repeat; */
    background-size: cover;
    padding: 0;
  }

 #about h2 {
    font-size: 32px;
    color: #0c2e8a;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    padding-bottom: 20px;
  }

#services {
  padding: 4rem;
  width: 100%;
  display: flex;
  background-color: #fff;

  .first-half  {
  width: 50%;
  background-size: contain;
  /* background-image: linear-gradient(to right, transparent 50%, #ffffff 50%); */
  }
  .second-half {
    width: 50px;
    height: 100%;
  }

  .card-service{
    position: relative;
    width: 90vw;
  }
}
 #services h2 {
  font-size: 32px;
  color: #0c2e8a;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  padding-bottom: 20px;
}

#FAQSection h2 {
  font-size: 32px;
  color: #0c2e8a;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  padding-bottom: 20px;
}

  .section-content {
    margin: 1em;
    font-size: 1em;
    line-height: 24px;
  }

  .service-section-sub-heading {
    font-weight: bold;
    font-size: 1.5em;
  }

  #hero .slider {
    z-index: 8;
    height: 60vh;
    overflow: hidden;
    width: 100vw;
    /* height: 100vh; */
    position: relative;
  }

 
  
  /* .slider .slide:nth-child(5) {
    background-image: url("https://images.unsplash.com/photo-1583161178154-c362b3459d29?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80");
    animation-delay: -8s;
  } */
  
  


  
  #hero .slider .slide {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition-property: opacity;
    animation: slide 10s infinite;
  }

  .slider .slide:nth-child(1) {
    background-image: url("../../assets/images/hero-carousal/1.jpeg");
    animation-delay: 0;
  }
  
  .slider .slide:nth-child(2) {
    background-image: url("../../assets/images/hero-carousal/2.jpeg");
    animation-delay: -2.5s;
  }
  
  .slider .slide:nth-child(3) {
    background-image: url("../../assets/images/hero-carousal/3.jpeg");
    animation-delay: -5s;
  }
  
  .slider .slide:nth-child(4) {
    background-image: url("../../assets/images/hero-carousal/4.jpeg");
    animation-delay: -7.5s;
  }

  .react-multiple-carousel__arrow{
    z-index: 995 !important;
  }

    
  @keyframes slide {
    0%, 15%, 100% {
      transform: translateX(0);
      animation-timing-function: ease;
    }
    20% {
      transform: translateX(-100%);
      animation-timing-function: step-end;
    }
    95% {
      transform: translateX(100%);
      animation-timing-function: ease;
    }
  }