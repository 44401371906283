
.about-us {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 5rem;

    .about-text {
        /* width: 50%; */
        background: #16486E;
        padding-top: 4rem;
        padding-bottom: 4rem;

        h6 {
            color: #fff;
            /* display: flex;
            justify-content: center; */
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        p {
            font-size: 15px;
            width: 70%;
            margin: auto;
        }
    }

    .container {
        position: relative;
        /* width: 600px; */
        height: 600px;
        min-height: 0px !important;
        .circle {
            position: absolute;
            border-radius: 50%;
        }

        .circle1 {
            width: 150px;
            height: 150px;
            background-color: #90a4ae;
            top: 395px;
            left: 40px;
        }

        .circle2 {
            width: 120px;
            height: 120px;
            background-color: #90a4ae;
            top: 60px;
            left: 425px;
        }

        .square {
            position: absolute;
            width: 400px;
            height: 400px;
            background-color: #d3d3d3;
            border-radius: 20px;
            top: 100px;
            left: 100px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 1s ease-in-out, opacity 1s ease-in-out;
            }

            .fade {
                opacity: 0;
                transform: rotateY(180deg);
            }
        }
    }
}


/* Container for the entire insight section */
.insight-container {
    display: flex;
    align-items: stretch; /* Make both columns stretch to fill container height */
    justify-content: space-between; /* Space out the text and images */
    background-color: #f3f6f9; /* Light gray background */
    border-radius: 12px; /* Rounded corners */
    padding: 30px; /* Padding around the container */
    margin: 20px auto; /* Center the container */
    max-width: 1200px; /* Max width for the container */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  /* Styles for the text section */
  .insight-text {
    flex: 1; /* Take up equal space as the images section */
    background-color: #264c73; /* Dark blue background */
    color: #ffffff; /* White text color */
    padding: 40px; /* Padding inside the text box */
    border-radius: 8px; /* Rounded corners */
    display: flex;
    flex-direction: column; /* Arrange content in a column */
    justify-content: center; /* Center the text vertically */
  }
  
  /* Heading styles */
  .insight-text h2 {
    font-size: 2em; /* Larger font size for heading */
    margin-bottom: 20px; /* Space below the heading */
  }
  
  /* List styles */
  .insight-text ul {
    list-style-type: none; /* Remove default bullet points */
    padding: 0; /* Remove default padding */
  }
  
  /* List item styles */
  .insight-text li {
    font-size: 1.1em; /* Slightly larger font size */
    margin-bottom: 15px; /* Space between list items */
    line-height: 1.5; /* Better line spacing */
    position: relative; /* Position for the bullet points */
    padding-left: 20px; /* Space for custom bullet points */
  }
  
  /* Custom bullet point styles */
  .insight-text li::before {
    content: '•'; /* Custom bullet point */
    color: #ffdd00; /* Yellow color */
    position: absolute; /* Position it absolutely */
    left: 0; /* Align to the left */
    top: 0; /* Align to the top */
  }
  
  /* Styles for the image section */
  .insight-images {
    flex: 1; /* Take up equal space as the text section */
    display: flex;
    gap: 20px; /* Space between the images */
    padding-left: 20px; /* Space between text and images */
    align-items: center; /* Center images vertically */
  }
  
  /* Styles for each image */
  .insight-image {
    width: calc(50% - 10px); /* Set each image to take up half the space minus the gap */
    border-radius: 8px; /* Rounded corners for images */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for images */
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  }
  