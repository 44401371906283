.miscellaneous-main-container {
    width: 100%;
    position: relative;

    .table-ticker{
        position: absolute;
        width: 100%;

        .miscellaneous-header {
            h1{
                font-family: 'Futura PT Demi';
                font-size: 24px;
                color: #114d74;
                font-weight: bolder;
            }
        }
    }
}
@media (min-width: 1500px) {
    .box-table {
        justify-content: center !important;
    }
}