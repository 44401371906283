@import '../../Fonts.css';

.im-container {
    width: 100%;

    .im-tablist {
        background-color: #E5F1FB;
        position: fixed;
        width: 100%;
        z-index: 1111;

        .Mui-selected {
            color: #154A72;
            font-weight: bolder;
        }

        span {
            font-family: 'Futura PT Heavy';
            font-size: 13px;
        }
    }

    .im-tablepanel {
        /* padding: 24px 0px; */
        padding-top: 18px;

        p {
            font-family: 'Futura PT Book' !important;
        }

        .tp-header-main {
            position: absolute;
            top: 7.5rem;
            display: flex;
            align-items: baseline;
            width: 100%;
            margin-bottom: 10px;
            justify-content: center;
            /* left: 23px; */

            .tf-indexmovers {
                width: fit-content;
                display: flex;
                flex-direction: column;
            }

        }
        
        
    }
}
.im-ohlc {
    display: flex;
    /* padding: 5px; */
    margin-top: 3px;
    color: #fff;
    border: 1px solid #154A72;
    width: fit-content;
    border-radius: 5px;
    background-color: #DCE9F3;

    .im-oh,
    .im-lc,
    .im-rp {
      
        p {
            font-size: 11px;
            padding: 2px 5px;
            /* color: #339249; */
            border-bottom: 1px solid #154A72;
            font-weight: bold;
            white-space: nowrap;

            span {
                font-size: 11px;
                font-weight: bold;
                /* color: #339249; */
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .im-oh,.im-lc {
        border-right: 1px solid #154A72;

    }
}

.border-indices {
    border: 1px solid #154A72;
}

.border-indices:not(:first-child) {
    border-top: 0px solid #154A72;
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
}

/* indices-table-container */
.indices-table-container {
    /* style={{ borderRadius: "10px",marginTop:'44px !important', height:` calc(100vh - 200px)`, width:'80%',margin:"auto"}} */
    border-radius: 10px !important;
    /* margin-top: 45px !important; */
    height: calc(100vh - 145px);

    table {
        padding: 2px 10px;
    }
}

.ohlc {
    font-weight: bold;
    font-family: 10px;
}

.high-color {
    color: #339249;
}

.low-color {
    color: #DA2424;
}

.MuiSwitch-track {
    background-color: #154a72 !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: #154a72 !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.niftyplus {
    .im-ohlc {
        display: flex;
        /* padding: 5px; */
        margin-top: 3px;
        color: #fff;
        border: 1px solid #154A72;
        width: fit-content;
        border-radius: 5px;
        background-color: #DCE9F3;
        font-family: "Futura PT Book";

        .im-oh,
        .im-lc,
        .im-rp {

            p {
                font-size: 11px;
                padding: 2px 5px;
                /* color: #339249; */
                border-bottom: 1px solid #154A72;
                font-weight: bold;
                white-space: nowrap;
                font-family: "Futura PT Book";

                span {
                    font-size: 11px;
                    font-weight: bold;
                    /* color: #339249; */
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .im-oh,
        .im-lc {
            border-right: 1px solid #154A72;

        }
    }

    .border-timeframe {
        border: 2px solid grey;
        border-bottom: 1px solid grey;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .header-timeframe {
        color: #fff;
        background-color: #154A72;
        border-bottom: 1px solid lightgray;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .indice-border {
        padding: 3px;
        font-family: "Futura PT Book";

        p{
            font-family: "Futura PT Book";
        }
        /* background-color: #b0bcac;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px; */
    }
}

/* 
.standard-observ-futures {
    span {
        font-family: "Futura PT Book";
        font-weight: 600 !important;
        font-size: 12px;
    }

    .points {
        margin-top: 5px !important;
    }

    .observations {
        padding-left: 4.5rem;
    }
} */
.tp-header-main {
    position: absolute;
    top: 7.5rem;
    display: flex;
    align-items: baseline;
    width: 100%;
    margin-bottom: 10px;
    justify-content: center;
    /* left: 23px; */

    .tf-indexmovers {
        width: fit-content;
        display: flex;
        flex-direction: column;
    }

    .im-ohlc {
        display: flex;
        /* padding: 5px; */
        margin-top: 3px;
        color: #fff;
        border: 1px solid #154A72;
        width: fit-content;
        border-radius: 5px;
        background-color: #DCE9F3;

        .im-oh,
        .im-lc,
        .im-rp {

            p {
                font-size: 11px;
                padding: 2px 5px;
                /* color: #339249; */
                border-bottom: 1px solid #154A72;
                font-weight: bold;
                white-space: nowrap;

                span {
                    font-size: 11px;
                    font-weight: bold;
                    /* color: #339249; */
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .im-oh,
        .im-lc {
            border-right: 1px solid #154A72;

        }
    }




}

.tp-header {
    position: relative;
    display: flex;
    justify-content: center;
    /* margin-right: 5rem; */
    margin-bottom: 15px;
    /* width: 35%; */

    .label-header {
        font-family: 'Futura PT Heavy';
        margin: 0rem 2rem;
        font-size: 18px;
        font-weight: 600;
        color: #154A72;
        display: flex;
        align-items: end;
        justify-content: center;

        span {
            display: flex;
            align-items: center;
        }
    }

    .label-desc {
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.tp-header {
    position: relative;
    display: flex;
    justify-content: center;
    /* margin-right: 5rem; */
    margin-bottom: 15px;
    /* width: 35%; */

    .label-header {
        font-family: 'Futura PT Heavy';
        margin: 0rem 2rem;
        font-size: 18px;
        font-weight: 600;
        color: #154A72;
        display: flex;
        align-items: end;
        justify-content: center;

        span {
            display: flex;
            align-items: center;
        }
    }

    .label-desc {
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.button-grp {
    height: fit-content;
    /* margin-left: 4.6rem; */

    .group-button {
        padding: 2%;
        background-color: #b6cfe3;
        color: #333333;
        min-width: 35px;

        span {
            /* font-weight: bolder; */
            font-family: 'Futura PT Heavy';
            font-size: 10px;
        }
    }

    .selected {
        background-color: #4D8FCC;
        color: #fff;
    }
 
}

.switch-view {
    border: 1px solid #144368;
    border-radius: 10px;
    padding: 0px 10px;

    label {
        margin: 0px !important;
        .MuiSwitch-sizeSmall{
            padding: 7px !important;
        }
        .MuiSwitch-switchBase {
            font-family: 'Futura PT Book';
            font-size: 11px;
            padding: 4px !important;
        }
    }
}

.contributors {
    width: 100%;
    gap: 1%;
    /* display: flex; */
    justify-content: center;
    position: relative;
    /* top: 32px; */
    left: 0px;
    /* padding-bottom: 20px; */

    /* .p-contributors {
        width: 30%;
        margin-right: 30px;
    }

    .n-contributors {
        width: 30%;
    } */

    .positive {
        color: #339249;
        font-weight: bold;
        font-size: 15px;
    }

    .negitive {
        color: #DA2424;
        font-weight: bold;
        font-size: 15px;
    }

    .table-head {

        th {
            background-color: #154A72;
            font-family: "Futura PT Book";
            color: #ffffff;
            padding: 0px 11px;
            border-right: 1px solid #ffffff;
        }
    }

    .table-row {
        td {
            font-family: "Futura PT Book";
            background-color: #ffff;
            padding: 3px 14px;
            border-right: 1px solid lightgray;
        }
    }

    .contributes-Top {
        display: flex;
        /* justify-content: space-around; */
        margin-bottom: 5px;

        .pos-cont {
            color: #339249;
            font-weight: bold;
            font-size: 16px;
        }

        .neg-cont {
            color: #DA2424;
            font-weight: bold;
            font-size: 16px;
        }


    }

    .contributes {
        font-size: 12px;
        align-self: center;
    }
}

.standard-observ-indices {
    .observations{
        span{
            font-weight: 600 !important;
        }
        svg{
            font-size: 1.3rem !important;
        }
    }
}

.nse-border {
    border-bottom: 2px solid gray;
}

.indice-candle {
    border-top: 2px solid gray;
}

.plusplus-border {
    border-radius: 15px;
    border-bottom: 2px solid gray !important;
}
.indice-candle-nsePlusePluse{
    border: 1px solid rgba(0,0,0,0.4);
    border-radius: 5px ;
    margin: 2px 2px 5px 2px;
    padding: 2px;
    .im-ohlc{
        background-color: #fff;
    }
}