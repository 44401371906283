@import url('../../Fonts.css');

/* Homepage css */
.main-dashboard {
    width: 100%;
    display: flex;
    /* margin-top: 20px; */
    height: calc(100vh - 104px);
    /* padding: 0% 1%; */
    font-family: 'Futura PT Book';
    top: 95px;
    position: relative;
}

.customers-list {
    width: 15%;
}



@media screen and (max-width: 960px) {
    /* .main-dashboard {
        display: block;
    } */

    .customers-list {
        width: 20%;
        padding: 0px 25px;
        margin-bottom: 10px;
    }

    .tables-block {
        width: 100% !important;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;

    }

    .accordian-list {
        width: 100%;
        max-height: 250px;
        overflow: auto;
    }
}

.paper-content {
    height: calc(100% - 52px) !important;
}

/* -------------------------------------------- */

/* menu-bar css */
.menubar-button {
    color: #fff !important;
    font-size: 13px !important;
    font-family: 'Futura PT Book' !important;
    font-weight: 400 !important;
    padding: 15px !important;
}

.menubar :hover {
    background-color: #18598a !important;
    cursor: pointer !important;
}

button:focus {
    outline: 0px !important;
    outline: 0px auto -webkit-focus-ring-color !important;
}


/* -------------------------------------------- */

/* customer list css */
.customer-box {
    /* border-right: 1px solid #444444; */
    /* border-radius: 4px; */
    height: 100%;
}

.search-and-dropdown {
    display: block;
}

.list-search .MuiInput-underline:after {
    border-bottom: 0px !important;
}

.select-dropdown {
    /* min-width: 100px !important; */
    width: 100%;
    display: flex;
    align-items: center;
    background: rgba(21, 74, 114, 0.39);

    svg {
        margin-right: 7px;
    }
}

.select-dropdown .MuiInput-underline:after {
    border-bottom: 0px !important;
}

.list-search {
    display: flex;
    justify-content: center;
    width: 100%;
    background: rgba(236, 236, 236, 0.75);
}

.MuiInputBase-root {
    padding: 5px;
}

::placeholder {
    font-size: 13px !important;
    font-family: 'Futura PT Book' !important;
}

input {
    font-size: 13px !important;
    font-family: 'Futura PT Book' !important;
}

.list-search+ul {
    /* margin: 0px 10px !important; */
    padding: 0px !important;
    height: 90% !important;
    /* background-color: #c5e0f47d !important; */
    text-transform: capitalize;

}

.selected-item {
    background-color: lightblue;
    /* Replace with your desired color */
}


.customScrollbar {
    overflow: auto;
}
 
/* For Webkit browsers (Chrome, Safari, Edge) */
.customScrollbar::-webkit-scrollbar {
    width: 6px;
    /* Adjust the width to make it thinner */
}
 
.customScrollbar::-webkit-scrollbar-thumb {
    background-color: darkgray;
    /* Adjust the color of the scrollbar */
    border-radius: 10px;
    /* Optional: makes the scrollbar thumb rounded */
}
 
.customScrollbar::-webkit-scrollbar-track {
    background: rgb(255, 254, 254);
    /* Adjust the color of the track */
    border-radius: 10px;
    /* Optional: makes the track rounded */
}

.listitem {
    display: flex !important;
    justify-content:space-between;
    gap:8px;
    border-bottom: 1px solid lightgray !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
}

.list-csymbol span {
    font-size: 13px;
    font-family: 'Futura PT Book';
    font-weight: bold;
}

.list-cname span {
    font-size: 10px;
    font-family: 'Futura PT Book';
    color: rgba(102, 102, 102, 1);
}

.listitem:hover {
    background: rgba(203, 221, 236, 1) !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 0px !important;
}

.MuiInput-underline:before {
    border-bottom: 0px !important;

}

.MuiListItem-gutters {
    /* border-bottom: 1px solid #bebdbd !important; */
    border-bottom: 1px solid rgba(237, 243, 248, 1)
}

.MuiPaper-elevation4 {
    box-shadow: none !important;
}


/* --------table css ----------------- */

.tables-block {
    background-color: #f0f4fc;
    /* padding: 10px 0px; */
    width: 92%;
    height: calc(100vh - 104px);
    overflow-y: auto;
    overflow-x: hidden;
    /* padding-bottom: 15px; */
}

.table-ticker {
    display: flex;
    padding: 0px 6px;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    margin-top: 5px;
    background-color: #d9eef1;
}

.company-cmp {
    display: flex;
    align-items: center;
    background: #154A72;
    border-radius: 5px;
}

.indices-cmp {
    display: flex;
    align-items: center;
}

.details-heading {
    color: rgba(102, 102, 102, 1);
    font-size: 12px;
    font-family: 'Futura PT Book';
}

.details-context {
    color: #154A72;
    font-size: 14px;
    font-family: 'Futura PT Book';
    font-weight: 600;
    white-space: nowrap;
    margin-left: 10px;
}

/* .pricing::before{
    color: #000000;
    font-weight: 600;
    content: "₹";
    margin-right: 5px;
} */
.pricing {
    display: flex;
    font-weight: 600;
}

@media screen and (max-width: 960px) {
    .indices-cmp {
        /* display: block; */

        span {
            display: none;
        }
    }

    .details-context {
        margin-left: 0px;
        margin-right: 10px;
    }
}

/* @media screen and (max-width: 450px) {
    .company-cmp {
        display: inline;
    }
} */


/* ----- investers-trading-tabs ----- */

.investers-trading-tabs {
    position: fixed;
    /* background: red; */
    z-index: 11;
    bottom: 2px;
    left: 38%;
    /* transform: translateX(-49%); */
    /* display: flex;
    justify-content: center; */
    /* width: 37%; */
}
label{

    span{
        font-size: 13px !important;
        font-family: 'Futura PT Book' !important;
        padding: 0px !important;
    }
}
.tabs-grid {
    background-color: #154A72;
    border-radius: 25px;
    min-height: min-content !important;

    .Mui-selected {
        background-color: #4D8FCC;
        color: #fff !important;
        /* padding: 0%; */
        min-width: 0px;
    }

    span {
        font-size: 11px;
        color: #fff;
        font-family: "Futura PT Book";
    }

    .tab-button {
        min-width: 0px;
        min-height: min-content;
        padding: 4px 8px !important;
    }

    .PrivateTabIndicator-colorSecondary-154 {
        display: none;
    }
}

.PrivateTabIndicator-root-27 {
    display: none !important;
}


/* common-ticker */
.trading-ticker {
    background-color: #fff;
    /* padding-bottom: 8px; */
    position: fixed;
    /* For older versions of Firefox */
    width: -webkit-fill-available;
    /* For Safari and Chrome */
    width: fill-available;
    width: -moz-available;
    /* Standard */

    z-index: 111;
    box-shadow: 0px 4px 50px 0px #00000026;


    .tview-ticker {
        display: flex;
        align-items: center;
        font-family: 'Futura PT Book';

        padding: 10px;

        .green {
            color: rgb(51, 146, 73);
        }

        .red {
            color: rgb(218, 36, 36);
        }

        .tview-context {
            margin-right: 5px;
            font-family: 'Futura PT Book';
            font-size: 15px;
            font-weight: 600;
            line-height: 24px;
            color: #154A72;
        }

        .tviewpricing {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 600;
        }
    }

    .info-container {
        width: 100%;
        margin: 6px 8px;
        display: flex;
        /* justify-content: space-between; */
        gap: 1%;

        .info-box {
            width: 32%;
            /* display: flex; */
            /* justify-content: space-evenly; */
            background-color: #DFEAF2;
            border-radius: 8px;
            /* padding: 0px 0px 0px 7px; */
            border: 1px solid #154A72;

            .time-frame {
                height: 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: 'Futura PT Book';
                font-size: 13px;
                font-weight: 600;
                background: #154a72;
                border-radius: 5px;
                padding: 0px 5px;
                color: #fff;
                margin: 0px 0px 0px 7px;

                .particular-frame,
                .percent {
                    font-family: 'Futura PT Book';
                    font-size: 13px;
                    font-weight: 600;
                    /* background-color: #fff; */
                }

                .percent {
                    background-color: #fff;
                    border-radius: 5px;
                    padding: 0px 7px;
                }

            }

            /* .high-low,
            .prediction {
                font-family: 'Futura PT Book';
                font-size: 13px;
                font-weight: 600;

                .high {
                    color: #339249;
                }

                .low {
                    color: #DA2424;
                }
            } */
            .high-low {
                display: grid;
                grid-template-columns: 1fr 1fr;
                /* column-gap: 4px; */
                font-family: 'Futura PT Book';
                font-size: 13px;
                font-weight: 600;

                .high {
                    color: #339249;
                }

                .low {
                    color: #DA2424;
                }

                p {
                    border: 1px solid #fff;
                    padding: 1px 5px;
                    white-space: nowrap;
                }

            }

            .indicator {
                display: flex;
                align-items: center;
                width: 100px;
            }

            .prediction {
                font-size: 12px;
                color: #154A72 !important;
            }
        }
    }
}

.high-low-box {
    width: 100%;
    border-left: 2px solid white;
    border-top: 2px solid white;
    /* border-top-right-radius: 10px; */
    /* border-bottom-left-radius: 10px; */
    padding-bottom: 3px;
    padding-top: 3px;

    .high {
        color: #339249;
    }

    .low {
        color: #DA2424;
    }

    .fontCss {
        font-size: 12px;
        font-weight: bold;
        font-family: 'Futura PT Book';
        margin-left: 5px
    }
}

.invest-tables {
    /* width: fit-content; */
    width: 100%;
    /* margin-left: 5px; */
    top: 155px;
    position: relative;
    display: flex;
    gap: 4px;

    .etf-col {
        width: 25%;
    }
}

.etfs-table {
    border-radius: 10px !important;
    /* margin-top: 45px !important; */
    height: calc(100vh - 145px);

    /* table {
        width: 80%;
        margin: auto;
    } */
}

.invest-tables-etfs {
    top: 110px;
    /* width: -moz-fit-content; */
    width: fit-content;
    /* margin-left: 5px; */
    position: relative;
    display: flex;
    gap: 4px;
    width: 98%;
    margin: auto;
    padding-bottom: 6%;
    height: calc(100vh - 285px);
    overflow: auto;


    .etf-col {
        width: 27%;
    }

    .for-indices {
        width: 27%;
        table{
            th,td{
                text-align: center;
                padding: 3px 10px !important;
            }
        }
    }

    .for-indices-investor {
        width: 25%;
        table{
            th,td{
                text-align: center;
                padding: 3px 8px !important;
            }
        }
    }


}

.table-heading {
    background-color: #A3C1D9;
    color: #154A72;
    font-family: "Futura PT Book";
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
    padding: 5px 15px;
    white-space: nowrap;
}

.table-head {
    background-color: #154A72;

    th {
        font-family: "Futura PT Book";
        color: #ffffff;
        padding: 0px 4px;
        border-right: 1px solid #ffffff;
        white-space: nowrap;

    }

    th:last-child {
        border-right: 0px solid #ffffff;

    }
}

.table-row,
.table-row-etfs {
    td {
        background-color: #fff;
        padding: 3px 9px;
        /* border-right: 1px solid #ffffff; */
        font-weight: 600;
        font-size: 12px;
        border: 1px solid lightgray;
    }

    p {
        font-family: "Futura PT Book";
        font-size: 12px;
        font-weight: 600;
    }
}


.tt-container {
    display: flex;
    width: 100%;
    height: calc(100vh - 95px);

    .tabs-container {
        /* width: 13%; */
        /* background: red; */
        background-color: #fff;
        border-right: 1px solid lightgray;

        .tt-tablist {
            .Mui-selected {
                color: #154A72;
                font-weight: bolder;
                background-color: #d2f1f5;
            }

            span {
                font-family: 'Futura PT Book';
                font-size: 13px;
                font-weight: bold;
            }

            .tt-tab {
                border-bottom: 1px solid lightgray;
                white-space: nowrap;
                padding: 0px;
                min-height: 35px !important;
            }
        }
    }

    .top-tabs {
        color: darkslategray;
        font-weight: bold;
        font-size: 12px;
    }

    .tbl-container {
        width: 88%;

        /* width: -webkit-fill-available; */
        .tt-tablepanel {
            padding: 0px;

            .bees-ticker {
                position: relative;
                background-color: #d9eef1;
            }
        }
    }
}

.standard-observ-eq {
    .slider-container{
        padding-top: 10px !important;
    }
    span{
        font-family: "Futura PT Book";
        font-weight: 600 !important;
        font-size: 10px !important;
    }

    .points{
        margin-top: 0px !important;
    }

    .slider-bar{
        margin-top: 15px !important;
    }

}

.standard-observ-tf {
    .slider-container{
        padding-top: 10px !important;
    }
    span{
        font-family: "Futura PT Book";
        font-weight: 600 !important;
        font-size: 12px;
    }

    .points{
        margin-top: 5px !important;
    }

    .slider-bar{
        margin-top: 15px !important;
    }

    .observations{
        padding-left: 8%;
    }
}

.border-timeframe {
    border: 2px solid grey;
    border-bottom: 1px solid grey;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: fit-content;
}

.header-timeframe {
    color: #fff;
    background-color: #154A72;
    border-bottom: 1px solid lightgray;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}