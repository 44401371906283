.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.signup-dialog .MuiDialog-paperWidthSm {
  width: 80%;
  padding-bottom: 20px;
  border-radius: 15px;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader {
  color: #FFF;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em
  }

  40% {
    box-shadow: 0 2.5em 0 0
  }
}


.lineloader {
  width: 8px;
  height: 40px;
  border-radius: 4px;
  display: block;
  margin: 20px auto;
  position: relative;
  background: #ff0303;
  color: #04fc04;
  box-sizing: border-box;
  animation: animloader 0.3s 0.3s linear infinite alternate;
}

.lineloader::after,
.lineloader::before {
  content: '';
  width: 8px;
  height: 40px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  box-sizing: border-box;
  animation: animloader 0.3s 0.45s linear infinite alternate;
}

.lineloader::before {
  left: -20px;
  animation-delay: 0s;
}

@keyframes animloader {
  0% {
    height: 48px
  }

  100% {
    height: 4px
  }
}


.climbloader {
  position: relative;
  width: 75px;
  height: 100px;
  background-repeat: no-repeat;
  background-image: linear-gradient(#DDD 50px, transparent 0),
    linear-gradient(#DDD 50px, transparent 0),
    linear-gradient(#DDD 50px, transparent 0),
    linear-gradient(#DDD 50px, transparent 0),
    linear-gradient(#DDD 50px, transparent 0);
  background-size: 8px 100%;
  background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px;
  animation: pillerPushUp 4s linear infinite;
}

.climbloader:after {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #de3500;
  border-radius: 50%;
  animation: ballStepUp 4s linear infinite;
}

@keyframes pillerPushUp {

  0%,
  40%,
  100% {
    background-position: 0px 90px, 15px 78px, 30px 66px, 45px 58px, 60px 50px
  }

  50%,
  90% {
    background-position: 0px 50px, 15px 58px, 30px 66px, 45px 78px, 60px 90px
  }
}

@keyframes ballStepUp {
  0% {
    transform: translate(0, 0)
  }

  5% {
    transform: translate(8px, -14px)
  }

  10% {
    transform: translate(15px, -10px)
  }

  17% {
    transform: translate(23px, -24px)
  }

  20% {
    transform: translate(30px, -20px)
  }

  27% {
    transform: translate(38px, -34px)
  }

  30% {
    transform: translate(45px, -30px)
  }

  37% {
    transform: translate(53px, -44px)
  }

  40% {
    transform: translate(60px, -40px)
  }

  50% {
    transform: translate(60px, 0)
  }

  57% {
    transform: translate(53px, -14px)
  }

  60% {
    transform: translate(45px, -10px)
  }

  67% {
    transform: translate(37px, -24px)
  }

  70% {
    transform: translate(30px, -20px)
  }

  77% {
    transform: translate(22px, -34px)
  }

  80% {
    transform: translate(15px, -30px)
  }

  87% {
    transform: translate(7px, -44px)
  }

  90% {
    transform: translate(0, -40px)
  }

  100% {
    transform: translate(0, 0);
  }
}

.MuiGrid-container {
  display: block !important;
}



.etfs-ticker {
  display: flex;
  gap: 10px;

  .label {
    font-weight: bold;
    color: #154A72;
    font-size: 18px;
  }

}

.bees-ticker {
  background-color: #fff;
  padding-bottom: 8px;
  position: absolute;
  width: -webkit-fill-available;
  z-index: 111;
  /* box-shadow: 0px 4px 50px 0px #00000026; */
  display: flex;
  align-items: center;
  padding: 6px 15px;


  .tview-context {
    margin-right: 5px;
    font-family: 'Futura PT Book';
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    color: #154A72;
  }

  .tviewpricing {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
  }
}

.font-futura {
  font-family: "Futura PT Book" !important;
}

.chart-container {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 320px) and (max-width: 768px) {
  .tt-tablist {
    .tt-tab {
      padding: 0px 30px !important;
    }
  }

  .investers-trading-tabs {
    left: 1.5%;
    /* width: 100%; */
  }

  .trading-ticker {
    top: 0px !important;
    position: relative !important;
  }

  .invest-tables,
  .invest-tables-etfs {
    top: 0px !important;
  }

  .MuiTabs-fixed {
    overflow: auto !important;
  }

  .main-dashboard {
    height: 100vh !important;
    width: 1500px !important;
  }

  .eu-text {
    height: -webkit-fill-available !important;
  }

  .journal-cards {
    display: block !important;
    padding: 0px !important;
  }

  .founder-img {
    height: -webkit-fill-available !important;
    width: -webkit-fill-available !important;
  }

  .about-founder {
    padding: 10px 20px;
  }

  .portfolio-card {
    display: none !important;
  }

  .about-tradabulls {
    padding: 0px !important;
  }

  .content {
    display: block !important;
  }

  .video-bar {
    flex-direction: column;
  }

  .video-play {
    order: 2;
  }

  .text-container {
    text-align: center;
    order: 1;
  }

  .youtube-videos {
    flex-direction: column;
    width: 100%;
    grid-template-columns: none !important;
  }

  .video-responsive {
    display: none;
    width: 100%;
  }

  .video-responsive:first-child {
    display: block;
    width: 100%;
    max-width: none;
  }

  iframe {
    width: 100%;
    height: auto;
  }

  .insight-container {
    flex-direction: column;
  }

  .insight-images {
    display: none !important;
  }

  .insight-text {
    margin-right: 0;
  }


  .reviews__container {
    flex-direction: column;
    align-items: center;
  }

  .reviews__card-container {
    flex-direction: column;
    width: 100%;
  }

  .reviews__card {
    min-width: 100%;
    margin: 10px 0;
  }

  .reviews__arrow {
    display: none;
  }

  .contact-container {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .android-device .dialog-box {
    width: 100%;
    top: 500px;
    left: 50%;
    transform: translate(0%, 0%);
  }
}
.MuiContainer-maxWidthLg{
  padding: 0% !important;
}
/* @media (min-width: 1024px) and (max-width: 1400px) { */
  .gaugechart-observations {
    position: absolute;
    top: 47%;
    left: 46%;
  }
/* } */

/* @media only screen and (min-width: 1401px) {
  .gaugechart-observations {
    position: absolute;
    top: 47%;
    left: 46%;
  }
} */