/* .review-card {
    background-color: #bce0fd;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #0055a7;
    box-shadow: 0px 0px 10px #fff;
}
.review-card__img-holder {F
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 1px;
}
.review-card__img-holder img {
    max-width: 100%;
}
.review-card:hover {
    box-shadow: 0px 0px 10px #7b7b7b db;
    background-color: #fff;
}
.review-card:hover .review-card__img-holder {
    background-color: #bce0fd;
}
.review-card__name {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
}
.review-card__location {
    font-size: 14px;
    line-height: 30px;
    margin-top: 12px;
}
.review-card__comment {
    font-size: 14px;
    line-height: 24px;
    margin-top: 32px;
}

.box  .MuiTypography-body1 {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
} */
.review-card {
    background: rgb(242, 242, 242);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px !important;
    width: 300px;
    height: 200px;
    overflow: auto;
    padding: 10px;
    border-radius: 8px;
}

.review-card__img-holder {
    height: 70px;
    width: 70px;
    background-color: rgba(205, 162, 162, 1);
    border-radius: 15px;
    /* margin: 5px 0px 0px 10px; */
}

.review-card__name {
    color: rgba(21, 74, 114, 1);
    font-size: 16px;
    font-weight: bold;
    margin-left: 15px;
}

.review-card__comment {
    font-size: 12px;
    margin-top: 5px;
}

.react-multi-carousel-track {
    /* transform: translate3d(0px, 0px, 0px) !important; */
    gap: 3%;
}

.testimonials-heading {
    /* font-family: Roboto; */
    font-size: 16px;
    color: rgba(38, 180, 75, 1);
    font-weight: 700;
    line-height: 23.44px;
    text-align: left;

}

.testimonials-subheading {
    /* font-family: Roboto; */
    font-size: 20px;
    font-weight: 800;
    line-height: 46.88px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(21, 74, 114, 1);

}

.testimonials-discp {
    /* font-family: Poppins; */
    font-size: 13px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;

}