.reviews__title {
    font-size: 26px;
    text-align: center;
    color: #c70000;
    font-weight: bold;
    margin-bottom: 20px;
}
.reviews{
    margin: 20px 0px;
}
.reviews__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 45px 0px;
}

.reviews__card-container {
    display: flex;
    justify-content: space-around;
    width: 85%;
}

.reviews__card {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    width: 300px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.reviews__avatar {
    background-color: #ccc;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    /* margin: 0 auto 15px; */
}

.reviews__stars {
    font-size: 20px;
    color: #ffb400;
    margin-left: 10px;
    /* margin-bottom: 15px; */
}

.reviews__name {
    display: flex;
    align-items: flex-start;
    font-weight: bold;
    margin-bottom: 10px;
}

.reviews__text {
    font-size: 14px;
    color: #333;
}

.reviews__arrow {
    background: none;
    border: none;
    font-size: 24px !important;
    cursor: pointer;
    color: #333;
}

/* .reviews__arrow--left {
    margin-right: 20px;
}

.reviews__arrow--right {
    margin-left: 20px;
} */