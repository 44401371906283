.faq-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding: 5% 10%;
    gap: 2%;
}

.img-container {
    display: flex;
}

.faq-accordians {
    width: 65%;
    /* row-gap: 5%; */
    display: grid;
}

.faq-info {
    display: grid;
}

.main-accordian {
    height: min-content;
    box-shadow: none !important;
    margin-bottom: 1%;
}

.accordian-summary {
    background: rgba(21, 74, 114, 1) !important;
    color: #fff !important;
    border-radius: 10px !important;

}

.main-accordian.MuiAccordion-root.Mui-expanded {
    margin: 0% 0% 1% 0% !important;
    background: rgba(21, 74, 114, 1);
    color: #fff;
    border-radius: 10px;
    padding-bottom: 10px;
    /* margin-bottom: 15px; */
}

.accordian-summary {
    .MuiIconButton-label svg {
        color: #fff !important;
    }
}

.accordian-summary.MuiAccordionSummary-root.Mui-expanded {
    min-height: 50px !important;
    height: 10px !important;
}

.accordian-details {
    padding: 0px 16px !important;
}


@media screen and (max-width: 450px) {
    .img-container {
        display: none;
    }

    .faq-accordians {
        width: 100%;
    }

    .faq-info {
        margin-bottom: 10px;
    }
}

#about {
    display: flex !important;
}