.youtube-section {
    background-color: #16486E;

    h6 {
        color: #fff;
        font-size: 30px;
        font-weight: bold;
        padding: 30px;
    }

    .youtube-videos {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(3, 1fr);
        padding: 20px 30px;

        .video-responsive {
            iframe {
                width: 100%;
                /* height: auto; */
                height: 210px;
            }
        }
    }
}