.headerCell {
    border: 1px solid white;
    color: #333333;
    text-align: start;
    padding: 0px;
    padding-bottom: 0px
}

.top-container {
    background-color: #154A72;
    padding: 6px 5px
}

.table {
    border: 0px solid;
    width: 100%;
    border-collapse: collapse;
}

.timeDur {
    font-family: Futura PT Book;
    font-weight: bold;
    font-size: 15px;
    align-items: center;
    transform: all 1s ease-in-out;

    &:hover {
        transform: scale(1.15)
    }
}

.tableCell {
    border: 1px solid lightgray;
    p{
        font-size: 14px;
        font-weight: bold;
        font-family: "Futura PT Book";
    }
  }

.top-tabs {
    span {
        font-family: 'Futura PT Heavy';
        font-size: 12px;
    }

    .Mui-selected {
        color: #154A72;
        font-weight: bolder;
    }
}