@import "../../Fonts.css";

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1b4765;
  padding: 20px;
  color: white;
}

.contact-header {
  font-size: 2em;
  margin-bottom: 30px;
}

.contact-center {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contact-image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 155px;
  padding: 31px;
}

.contact-image {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  object-fit: cover;
  border: 5px solid #346184;
}

.contact-info-wrapper {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 90%;
  top: 50%;
  transform: translateY(-50%);
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 40%;
  padding: 20px;
}

.contact-item {
  background-color: white;
  color: black;
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: fit-content;
}

.contact-icon {
  margin-right: 10px;
  font-size: 1.5em;
}

.left {
  align-items: flex-end;
  text-align: left;
}

.right {
  align-items: flex-start;
  text-align: left;
}


/* explore us */
.eu-active {
  color: #D0111C;
  background-color: #fff;
  border-radius: 5px;
  font-family: 'Futura PT Demi' !important;
  padding: 4px;
  cursor: pointer;
}

.eu-non-active {
  color: #fff;
  /* background-color: #D0111C; */
  /* border-radius: 5px; */
  font-family: 'Futura PT Book' !important;
  padding: 4px;
  text-decoration: underline;
}

.eu-non-active:hover {
  color: wheat;
  cursor: pointer;
  /* background-color: #D0111C; */
  /* border-radius: 5px; */

}

.eu-text {
  height: 130px;

  h1 {
    font-size: 20px;
    font-weight: 600;
    color: #17486d;
    text-shadow: rgba(157, 157, 157, 0.8) 2px 2px 4px;
    font-family: 'Futura PT Demi';
  }

  p {
    font-weight: 500;
    font-size: 16px;
    font-family: 'Futura PT Book';
    text-shadow: rgba(157, 157, 157, 0.8) 2px 2px 4px;
  }
}

.eu-behaviour-main {
  padding: 20px;

  .eu-behaviour {
    /* ackground: '#1e496f', padding: '7%', borderRadius: '10px' */
    background-color: #1e496f;
    padding: 6%;
    border-radius: 30px;

    h1 {
      display: flex;
      justify-content: center;
      font-size: 20px;
      color: rgba(38, 180, 75, 1);
      padding: 5px;
      font-family: 'Futura PT Demi';
      /* style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', color: '#fff', padding: '5px',fontFamily: 'Futura PT Heavy' }} */
    }

    p {
      color: #fff;
      font-family: 'Futura PT Book';
      font-size: 18px;
    }
  }
}

.eu-remaing {
  li {
    list-style: auto;
    margin-left: 10px;
    margin-top: 10px;

    span {
      margin: 5px;
      color: wheat;
      font-weight: 600;
      font-size: 18px;
    }
  }
}

.rm-main {
  width: 100%;
  display: flex;
  justify-content: center;

  .rm {
    background-color: #b0bcac;
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
    font-family: "Futura PT Demi";
  }
  .rm:hover{
    opacity: 0.8;
  }
}
.rl-main {
  width: 100%;
  display: flex;
  justify-content: center;

  .rl {
    background-color: wheat;
    border-radius: 5px;
    padding: 5px;
    margin-top: 10px;
    color: #4f4f4f;
    font-family: "Futura PT Demi";
  }
  .rl:hover{
    opacity: 0.8;
  }
}