#hero {
    width: 100%;
    height: 60vh;
    position: relative;
   /* background: url("../../assets/images/hero-carousal/1.jpeg") no-repeat; */
    background-size: cover;
    padding: 0;
    display: flex;
    justify-content: center;
    align-content: center;
  }

.grid-lines {
    display: grid;
    width: 100%; 
    height: 300px;
    border: 0px solid #ffffff;
    background:
        linear-gradient(to right, transparent 1px, #f2f1f1 1px, #f2f1f1 2px, transparent 2px) 0 0 / 40px 40px,
        linear-gradient(to bottom, transparent 1px, #f2f1f1 1px, #f2f1f1 2px, transparent 2px) 0 0 / 40px 40px;
    /* Adjust the size (20px) and color (#999) as per your design */
    background-color: #f0f0f000; /* Background color for the grid */
}

.empower {
  font-size: clamp(16px, 4vw, 32px) !important;
  color: #154A72;
  font-weight: bold !important;
  font-family: "Futura PT Heavy" !important;
}

.comprehensive {
  font-weight: 400 !important;
  color: #666666;
  font-size: 16px !important;
  font-family: "Futura PT Book" !important;
}

#hero .btn-get-started {
   font-family: "Futura PT Book" ;
    background: #26B44B;
    border: 0px solid;
    border-radius: 10px;
    margin-top: 0.5em;
  }
