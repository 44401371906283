.header-ticker {
    padding: 5px 0px;
    border-bottom: 1px solid rgb(27, 26, 26);
    display: flex;    /* Flex container to align items */
    animation: scroll 120s linear infinite;
    position: fixed;
    z-index: 99999999;
    /* background-color: #7c7c7c; */
    /* background-color: whitesmoke; */
    background-color: #2d3748;
    font-family: "Futura PT Book";
    font-weight: 600;
}

.company-details {
    display: flex;
    align-items: baseline;
    /* color: rgba(0, 0, 0, 0.85); */
    font-size: 13px;
    /* padding: 0px 5px; */
}

.company-details::before {
    content: "|";
    color: rgb(255, 255, 255);
    /* color: rgb(0, 0, 0); */
    margin: 2px 10px;
}

.company-name {
    color: rgb(255, 255, 255);
    width: max-content;
}
.company-currentprice{
    color: rgb(255, 255, 255);

}

.company-symbol {
    padding: 0px 5px;
    color: "rgba(255, 255, 255, 0.45)";
    font-size: 12px;
}

.percent-green {
    color: rgba(40, 167, 69, 1);
    display: flex;
    align-items: center;
    /* gap: 5px; */
}

.percent-red {
    color: rgba(255, 77, 79, 1);
    display: flex;
    align-items: center;
    /* gap: 5px; */
}

.sub-menu{
    ul{
        padding: 0% ;
        .submenu-tabs{
            border-bottom: 1px solid lightgray;
        }
        .submenu-tabs:hover{
            background-color: lightgray;
        }
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-120px * 50)); 
    }
}
.scrolling-element {
    animation: scroll 120s ease-in-out infinite; /* Using ease-in-out */
}

.attention-indicator {
    display: flex;
    align-items: center;
  }
  
  .blinking-star {
    display: inline-block;
    margin-left: 5px;
    color: red;
    font-size: 12px; /* Adjust size as needed */
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  