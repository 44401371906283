/* Portfolio.css */
.portfolio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px; /* Reduced padding to fit within 350px */
  position: relative;
  height: 350px; /* Set fixed height */
  background-color: #333;
  box-sizing: border-box;
  width: 80%;
  margin: auto;
}
.portfolio-text{
  display: grid;
  justify-content: center;
  h1{
    margin: 10px 0px;
    font-family: "Futura PT Demi";
    color: wheat;
    display: flex;
    justify-content: center;
    font-size: 26px;
  }
  p{
    margin-bottom: 10px;
    font-family: "Futura PT Book";
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 20px;
  }
}
.services {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Reduced gap to fit buttons within height */
}

.service-btn {
  background-color: #333;
  color: white;
  border: 2px solid wheat;
  padding: 8px 16px; /* Reduced padding */
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s ease;
  font-size: 16px; /* Slightly smaller font */
  position: relative;
  overflow: hidden;
  font-weight: 600;
}

.service-btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color: wheat;
  transform: translate(-150%, -150%) scale(0);
  transition: all 0.4s ease;
  border-radius: 50%;
  z-index: 0;
}

.service-btn:hover::before {
  transform: translate(-50%, -50%) scale(1);
  opacity: 0.2;
}

.service-btn:hover {
  color: wheat;
}

.service-btn.active {
  background-color: wheat;
  color: #444444;
}

.line-connection {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 1s forwards ease-in-out;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.video {
  /* background-color: #b0bcac; */
  background-color: wheat;
  padding: 15px 20px; /* Adjusted padding */
  border-radius: 15px;
  position: relative;
  width: 45%; /* Adjusted width to fit within 350px height */
  height: 100%;
  z-index: 1;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transition: transform 0.4s ease-in-out, background-color 0.4s ease-in-out;
  box-sizing: border-box;
}

.video-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #333;
  font-weight: bold;
  font-size: 16px; /* Slightly smaller font */
  text-align: center;
}

.video:hover {
  transform: scale(1.05);
  background-color: #b0bcac;
  p{
    color: #333 !important;
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .portfolio {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .services {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 20px;
    justify-content: center;
  }

  .video {
    width: 100%;
    height: auto;
  }

  .line-connection {
    display: none; /* Hide the connecting line on smaller screens */
  }
}
